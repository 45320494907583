import React, { useState, useEffect, useRef } from 'react';
import { withRouter } from "react-router-dom";
import { post, get } from '../../helpers/api_helper';
import { IsModuleAccess } from '../../helpers/Helper';
import Loader from '../../component/Loader';
import { POST_GET_COUPON_CODE_ADD_EDIT, POST_GET_COUPON_CODE_DETAIL } from '../../helpers/url_helper';
import { toast } from 'react-toastify';
import SimpleReactValidator from 'simple-react-validator';
import { Helmet } from "react-helmet";
import { Link, useHistory } from "react-router-dom";
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css";


const CouponCodeForm = props => {
    let history = useHistory();
    if (!IsModuleAccess('coupon_code')) {
        history.push("/");
    }

    const [isLoader, setLoader] = useState(0);
    const [FormInput, setFormInput] = useState({ user_voucher_id: '', discountType: '', amount: '', minimumOrderAmount: '', startDate: '', endDate: '', promo_code: '' });

    const simpleValidator = useRef(new SimpleReactValidator());
    const [, forceUpdate] = useState();
    const [title, setTitle] = useState('Add Coupon Code');

    const [ButtonDisabled, SetButtonDisabled] = useState(false);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');


    useEffect(() => {
        async function get_detaile() {

            if (props.match.params.id) {
                setTitle('edit Coupon Code')
                var res_data = await post(POST_GET_COUPON_CODE_DETAIL, { id: props.match.params.id });
                if (res_data.status) {
                    setStartDate(new Date(res_data.data.detail.startDate));
                    setEndDate(new Date(res_data.data.detail.endDate));
                    setFormInput(res_data.data.detail);
                } else {
                    toast.error(res_data.message);
                    history.push("/");
                }
            }
            setLoader(1);
        }
        get_detaile();
    }, [props.match.params.id])


    const handleInputChange = (event) => {
        event.persist();
        if(event.target.name == 'promo_code'){
            var code = event.target.value.replace(' ','').toUpperCase();
            setFormInput(inputs => ({ ...FormInput, [event.target.name]: code }));
        }else{
            setFormInput(inputs => ({ ...FormInput, [event.target.name]: event.target.value }));

        }
    }

    const handleSubmit = async (event) => {
        event.preventDefault();

        const formValid = simpleValidator.current.allValid()
        if (!formValid) {
            simpleValidator.current.showMessages(true);
            forceUpdate(1);
        } else {

            SetButtonDisabled(true);
            var res_data = await post(POST_GET_COUPON_CODE_ADD_EDIT, FormInput);
            if (res_data.status) {
                history.push("/coupon-code");
                toast(res_data.message);
            } else {
                toast.error(res_data.message);
            }
            SetButtonDisabled(false);


        }
    }


    const setDate = (event) => {
        if(event.target.name == 'endDate'){
            setEndDate(event.target.value);
        }else{
            setStartDate(event.target.value);
        }
        var date = new Date(event.target.value),
            mnth = ("0" + (date.getMonth() + 1)).slice(-2),
            day = ("0" + date.getDate()).slice(-2);
        var new_date = [date.getFullYear(), mnth, day].join("-");
        setFormInput(inputs => ({ ...FormInput, [event.target.name]: new_date }));
    }

    if (!isLoader) {
        return (<Loader></Loader>);
    }

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>ONDA | {title}</title>
            </Helmet>

            <div className='row mt-3'>
                <div className='col-sm-12'>
                    <div className='card'>
                        <div className="card-header">
                            <h6 className='m-0'>{title}</h6>
                        </div>
                        <div className='card-body'>
                            <form className="av-invalid" onSubmit={handleSubmit}>
                                <div className='row'>
                                    <div className="col-sm-4 form-group mb-3">
                                        <label for="">Promo Code</label>
                                        <input type="text" name="promo_code" value={FormInput.promo_code} onChange={handleInputChange} className="form-control" placeholder="Your Promo Code" />
                                        {simpleValidator.current.message('promo code', FormInput.promo_code, 'required')}
                                    </div>
                                    <div className=" col-sm-4 form-group mb-3">
                                        <label for="">Select Discount Type</label>
                                        <select name="discountType" value={FormInput.discountType} onChange={handleInputChange} className="form-control">
                                            <option value="">Select Discount Type</option>
                                            <option value="Percentage">Percentage</option>
                                            <option value="Fixed">Fixed</option>
                                        </select>
                                        {simpleValidator.current.message('discount type', FormInput.discountType, 'required')}
                                    </div>
                                    <div className="col-sm-4 form-group mb-3">
                                        <label for="">Amount Or Percentage</label>
                                        <input type="number" name="amount" value={FormInput.amount} onChange={handleInputChange} className="form-control" placeholder="Your Amount Or Percentage" />
                                        {simpleValidator.current.message('amount', FormInput.amount, 'required')}
                                    </div>

                                    <div className="col-sm-4 form-group mb-3">
                                        <label for="">Select Start Date</label>
                                        <DatePicker
                                            dateFormat="dd-MM-yyyy"
                                            selected={startDate}
                                            // minDate={new Date()}
                                            // maxDate={addMonths(new Date(), 6)}
                                            onChange={(value) => { setDate({ target: { name: 'startDate', value: value } }) }}
                                            className="form-control"
                                            placeholderText="Select Date"
                                        />
                                        {simpleValidator.current.message('date', FormInput.startDate, 'required')}
                                    </div>
                                    <div className="col-sm-4 form-group mb-3">
                                        <label for="">Select End Date</label>
                                        <DatePicker
                                            dateFormat="dd-MM-yyyy"
                                            selected={endDate}
                                            // minDate={new Date()}
                                            // maxDate={addMonths(new Date(), 6)}
                                            onChange={(value) => { setDate({ target: { name: 'endDate', value: value } }) }}
                                            className="form-control"
                                            placeholderText="Select Date"
                                        />
                                        {simpleValidator.current.message('date', FormInput.endDate, 'required')}
                                    </div>
                                    <div className="col-sm-4 form-group mb-3">
                                        <label for="">Minimum Order Amount</label>
                                        <input type="number" name="minimumOrderAmount" value={FormInput.minimumOrderAmount} onChange={handleInputChange} className="form-control" placeholder="Your Minimum Order Amount" />
                                        {simpleValidator.current.message('Amount', FormInput.minimumOrderAmount, 'required')}
                                    </div>

                                    <div className="col-sm-12 text-center mt-4">
                                        <button disabled={ButtonDisabled} type="submit" className='btn btn-primary c-btn-primary pt-1 pb-1 pl-3 pr-3'>{ButtonDisabled && <i className="fa fa-spinner me-2 fa-spin"></i>} Submit</button>
                                        <Link to="/coupon-code" className='btn btn-danger c-btn-danger ml-3 pt-1 pb-1 pl-3 pr-3'>Cancel</Link>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default withRouter(CouponCodeForm)
