import React from 'react';
import {Avatar} from '@material-ui/core';
import Logo from './../assets/img/logo.svg';
import { Link } from "react-router-dom";

import FeatherIcon from "feather-icons-react";

export default function Header(props) {

    const handleLangChange = () => {
        props.onChangeHandleMenu();            
    }

  return (
    <div className='nav_bar'>
        <div className='nav_bar_left text-center'>
            <img alt="ONDA" src={Logo} width='40px' />
        </div>
        <div className='nav_bar_right'>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-sm-6'>
                        <a href='#' onClick={handleLangChange}><FeatherIcon icon="menu" className="mt-10" /></a>
                    </div>
                    <div className='col-sm-6'>
                        <div className='dropdown'>
                            <Avatar alt="Remy Sharp" className='profile' src="/assets/img/avatar.png" href="#" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" />
                            <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                                <Link class="dropdown-item" to="/change-password">Change Password</Link>
                                <Link class="dropdown-item" to="/logout">Logout</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  );
}