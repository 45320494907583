import React, { useState, useEffect, useRef } from 'react';
import { withRouter } from "react-router-dom";
import { post, get } from '../../helpers/api_helper';
import Loader from '../../component/Loader';
import { POST_ADD_EDIT_ROLE, POST_GET_ROLE_DETAIL } from '../../helpers/url_helper';
import { toast } from 'react-toastify';
import SimpleReactValidator from 'simple-react-validator';
import { Helmet } from "react-helmet";
import { Link, useHistory } from "react-router-dom";

const UserRole = props => {
    let history = useHistory();

    const [isLoader, setLoader] = useState(0);
    const simpleValidator = useRef(new SimpleReactValidator());
    const [ButtonDisabled, SetButtonDisabled] = useState(false);
    const [form_inputs, setInputs] = useState({ 'country_id': 0, 'country_id': '',role_access : '' });
    const [title, setTitle] = useState('Add Role');
    const [, forceUpdate] = useState();

    useEffect(() => {
        async function get_detaile() {
            if (props.match.params.id) {
                setTitle('Edit Role');
                var res_data = await post(POST_GET_ROLE_DETAIL, { 'role_id': props.match.params.id }, {});
                if (res_data.status) {
                    setInputs(res_data.detail);
                } else {
                    toast.error(res_data.message);
                    history.push("/");
                }
            } else {
                var res_data = await post(POST_GET_ROLE_DETAIL, { 'role_id': '' }, {});
                if (res_data.status) {
                    setInputs(res_data.detail);
                } else {
                    toast.error(res_data.message);
                    history.push("/");
                }
            }
            setLoader(1);
        }
        get_detaile();
    }, [props.match.params.id])

    // form submit event
    const handleSubmit = async (event) => {
        event.preventDefault();
        const formValid = simpleValidator.current.allValid()
        if (!formValid) {
            simpleValidator.current.showMessages(true);
            forceUpdate(1);
        } else {
            SetButtonDisabled(true);
            var res_data = await post(POST_ADD_EDIT_ROLE, form_inputs, {});
            if (res_data.status) {
                toast(res_data.message);
                history.push("/user-role");
            } else {
                toast.error(res_data.message);
            }
            SetButtonDisabled(false);
        }
    }

    // input text change handler
    const handleInputChange = (event) => {
        event.persist();
        setInputs(inputs => ({ ...form_inputs, [event.target.name]: event.target.value }));
    }

    // input text change handler
    const handleInputChangeRole = (key, event) => {
        event.persist();
        var data = form_inputs;
        data.role_access[key].module_access = (event.target.checked) ? 1 : 0;
        setInputs(data);
    }


    if (!isLoader) {
        return (<Loader></Loader>);
    }

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>ONDA | {title}</title>
            </Helmet>

            <div className='row mt-3'>
                <div className='col-sm-8'>
                    <div className='card'>
                        <div className="card-header">
                            <h6 className='m-0'>{title}</h6>
                        </div>
                        <div className='card-body'>
                            <form className="av-invalid" onSubmit={handleSubmit}>
                                <div className="form-group mb-4">
                                    <label className="form-label text-capitalize">Role Name</label>
                                    <input name="role_name" value={form_inputs.role_name} onChange={handleInputChange} className="form-control" type="text" />
                                    {simpleValidator.current.message('role name', form_inputs.role_name, 'required')}
                                </div>

                                <div className='row'>
                                    {Object.entries(form_inputs.role_access).map(([key, value]) => (
                                        <>
                                            <div className='col-sm-4'>
                                                <label className="form-label text-capitalize">{value.name}</label>
                                            </div>
                                            <div className='col-sm-2'>
                                                <label class="switch">
                                                    <input name="email_enable" onChange={(event) => handleInputChangeRole(key, event)} defaultChecked={parseInt(value.module_access)} type="checkbox" />
                                                    <span class="slider round"></span>
                                                </label>
                                            </div>
                                        </>
                                    ))}
                                </div>
                                <div className="text-center mt-4">
                                    <button disabled={ButtonDisabled} type="submit" className='btn btn-primary c-btn-primary pt-1 pb-1 pl-3 pr-3'>{ButtonDisabled && <i className="fa fa-spinner me-2 fa-spin"></i>} Submit</button>
                                    <Link to="/user-role" className='btn btn-danger c-btn-danger ml-3 pt-1 pb-1 pl-3 pr-3'>Cancel</Link>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default withRouter(UserRole)
