import React, { useState, useEffect, useRef } from 'react';
import { withRouter } from "react-router-dom";
import { post, get } from '../../helpers/api_helper';
import { img_view, IsModuleAccess } from '../../helpers/Helper';
import Loader from '../../component/Loader';
import { GET_TABLE_BOOKING_TIME, POST_BOOK_TABLE, POST_GET_DETAIL_TABLE_BOOKING, POST_CHECK_AVAILABLE_TABLE } from '../../helpers/url_helper';
import { toast } from 'react-toastify';
import SimpleReactValidator from 'simple-react-validator';
import { Helmet } from "react-helmet";
import { Link, useHistory } from "react-router-dom";
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css";
import { addMonths } from 'date-fns';
const StripeCheckout2 = require('react-stripe-checkout').default;

const ChangePassword = props => {
    let history = useHistory();
    if (!IsModuleAccess('table_booking')) {
        history.push("/");
    }

    const [isLoader, setLoader] = useState(0);
    const [BookingDetail, setBookingDetail] = useState({ table_booking_id: '', booking_type: '', date: '', time: '', first_name: '', last_name: '', number_of_people: 1, phone_number: '', email: '', post_code: '', note: '' });
    const [dateList, setDateList] = useState([]);
    const [timeList, setTimeList] = useState([]);
    const [PartySize, setPartySize] = useState(0);
    const [ButtonDisabled, SetButtonDisabled] = useState(false);
    const simpleValidator = useRef(new SimpleReactValidator());
    const [, forceUpdate] = useState();
    const [title, setTitle] = useState('Add Table Booking');
    const [startDate, setStartDate] = useState('');
    const [minimum_person_save_card_details, set_minimum_person_save_card_details] = useState(0);


    useEffect(() => {
        async function get_detaile() {
            var date_res_data = await get(GET_TABLE_BOOKING_TIME);
            if (date_res_data.status) {
                setDateList(date_res_data.data.date_array);
                set_minimum_person_save_card_details(date_res_data.data.minimum_person_save_card_details);
            }

            if (props.match.params.id) {
                var res_data = await post(POST_GET_DETAIL_TABLE_BOOKING, { table_booking_id: props.match.params.id });
                if (res_data.status) {
                    setStartDate(new Date(res_data.data.detail.date));
                    BookingDetail.booking_no = res_data.data.detail.booking_no;
                    BookingDetail.date = res_data.data.detail.date;
                    BookingDetail.time = res_data.data.detail.time;
                    setBookingDetail(res_data.data.detail);
                    for (var date_value of date_res_data.data.date_array) {
                        if (date_value.date == res_data.data.detail.date) {
                            setTimeList(date_value.time);
                        }
                    }
                    CheckAvailableTable()
                } else {
                    toast.error(res_data.message);
                    history.push("/");
                }
            }
            setLoader(1);
        }
        get_detaile();
    }, [props.match.params.id])


    const handleInputChange = (event) => {
        event.persist();
        if (event.target.name == 'date') {
            for (var date_value of dateList) {
                if (date_value.date == event.target.value) {
                    setTimeList(date_value.time);
                }
            }
            BookingDetail.time = '';
            BookingDetail.date = event.target.value;
            BookingDetail.number_of_people = '';

            setBookingDetail(inputs => ({ ...BookingDetail, [event.target.name]: event.target.value }));
            CheckAvailableTable();
        } else if (event.target.name == 'time') {
            BookingDetail.number_of_people = '';
            BookingDetail.time = event.target.value;
            setBookingDetail(inputs => ({ ...BookingDetail, [event.target.name]: event.target.value }));
            CheckAvailableTable();
        } else {
            setBookingDetail(inputs => ({ ...BookingDetail, [event.target.name]: event.target.value }));
        }
    }

    const handleSubmit = async (event) => {
        event.preventDefault();

        const formValid = simpleValidator.current.allValid()
        if (!formValid) {
            simpleValidator.current.showMessages(true);
            forceUpdate(1);
        } else {

            SetButtonDisabled(true);
            if (BookingDetail.customer_id || parseInt(BookingDetail.number_of_people) < minimum_person_save_card_details) {
                var res_data = await post(POST_BOOK_TABLE, BookingDetail);
                if (res_data.status) {
                    history.push("/table-booking");
                    toast(res_data.message);
                } else {
                    toast.error(res_data.message);
                }
                SetButtonDisabled(false);
            } else {
                window.$('.StripeCheckout2').click();
                setTimeout(() => {
                    SetButtonDisabled(false);
                }, 5000);
            }

        }
    }

    const CheckAvailableTable = async (event) => {
        var res_data = await post(POST_CHECK_AVAILABLE_TABLE, { id: BookingDetail.booking_no, date: BookingDetail.date, time: BookingDetail.time });
        if (res_data.status) {
            setPartySize(res_data.booking_available)
        } else {
            toast.error(res_data.message);
        }
    }

    const handleToken = async (token, address) => {
        SetButtonDisabled(true);

        BookingDetail.token = token
        const response = await post(POST_BOOK_TABLE, BookingDetail);
        if (response.status) {
            history.push("/table-booking");
            toast(response.message);
        } else {
            toast.error(response.message);
        }
        SetButtonDisabled(false);
    }

    const setDate = (event) => {
        setStartDate(event);
        var date = new Date(event),
            mnth = ("0" + (date.getMonth() + 1)).slice(-2),
            day = ("0" + date.getDate()).slice(-2);
        var new_date = [date.getFullYear(), mnth, day].join("-");

        setTimeList([]);
        for (var date_value of dateList) {
            if (date_value.date == new_date) {
                setTimeList(date_value.time);
            }
        }
        BookingDetail.time = '';
        BookingDetail.date = new_date;
        BookingDetail.number_of_people = '';

        setBookingDetail(inputs => ({ ...BookingDetail, ['date']: new_date }));
        CheckAvailableTable();
    }

    if (!isLoader) {
        return (<Loader></Loader>);
    }

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>ONDA | {title}</title>
            </Helmet>

            <div className='row mt-3'>
                <div className='col-sm-12'>
                    <div className='card'>
                        <div className="card-header">
                            <h6 className='m-0'>{title}</h6>
                        </div>
                        <div className='card-body'>
                            <form className="av-invalid" onSubmit={handleSubmit}>
                                <div className='row'>
                                    <div className=" col-sm-4 form-group mb-3">
                                        <label for="">Select Booking Type</label>
                                        <select name="booking_type" value={BookingDetail.booking_type} onChange={handleInputChange} className="form-control">
                                            <option value="">Select Booking Type</option>
                                            <option value="Table Booking">Table Booking</option>
                                            <option value="Bottomless Brunch">Bottomless Brunch</option>
                                            
                                        </select>
                                        {simpleValidator.current.message('booking type', BookingDetail.booking_type, 'required')}
                                    </div>
                                    <div className="col-sm-4 form-group mb-3">
                                        <label for="">Select Date</label>
                                        <DatePicker
                                            dateFormat="dd-MM-yyyy"
                                            selected={startDate}
                                            minDate={new Date()}
                                            maxDate={addMonths(new Date(), 6)}
                                            onChange={setDate}
                                            className="form-control"
                                            placeholderText="Select Date"
                                        />
                                        {/* <select name="date" value={BookingDetail.date} onChange={handleInputChange} className="form-control">
                                            <option value="">Select Date</option>
                                            {dateList && Object.entries(dateList).map(([key, date]) => {
                                                return (<option value={date.date} key={key}>{date.date}</option>);
                                            })}
                                        </select> */}
                                        {simpleValidator.current.message('date', BookingDetail.date, 'required')}
                                    </div>
                                    <div className=" col-sm-4 form-group mb-3">
                                        <label for="">Start Time</label>
                                        <select name="time" value={BookingDetail.time} onChange={handleInputChange} className="form-control">
                                            <option value="">Select Time</option>
                                            {timeList && Object.entries(timeList).map(([key, date]) => {
                                                return (<option value={date.time} key={key}>{date.time}</option>);
                                            })}
                                        </select>
                                        {simpleValidator.current.message('time', BookingDetail.time, 'required')}
                                    </div>
                                    <div className="col-sm-4 form-group mb-3">
                                        <label for="">Party Size</label>
                                        <select name="number_of_people" value={BookingDetail.number_of_people} onChange={handleInputChange} className="form-control">
                                            <option value=''>Select Party Size</option>
                                            {Array.from(Array(PartySize), (e, i) => {
                                                return (<option value={++i}>{i}</option>)
                                            })}
                                        </select>
                                        {simpleValidator.current.message('number of people', BookingDetail.number_of_people, 'required')}
                                    </div>
                                    <div className="col-sm-4 form-group mb-3">
                                        <label for="">First Name</label>
                                        <input type="text" name="first_name" value={BookingDetail.first_name} onChange={handleInputChange} className="form-control" placeholder="Your First Name" />
                                        {simpleValidator.current.message('first name', BookingDetail.first_name, 'required')}
                                    </div>
                                    <div className="col-sm-4 form-group mb-3">
                                        <label for="">Last Name</label>
                                        <input type="text" name="last_name" value={BookingDetail.last_name} onChange={handleInputChange} className="form-control" placeholder="Your Last Name" />
                                        {simpleValidator.current.message('last name', BookingDetail.last_name, 'required')}
                                    </div>
                                    <div className="col-sm-4 form-group mb-3">
                                        <label for="">Phone</label>
                                        <input type="text" name="phone_number" value={BookingDetail.phone_number} onChange={handleInputChange} className="form-control" placeholder="Your Phone Number" />
                                        {simpleValidator.current.message('phone number', BookingDetail.phone_number, 'required')}
                                    </div>
                                    <div className="col-sm-4 form-group mb-3">
                                        <label for="">Post Code</label>
                                        <input type="text" name="post_code" value={BookingDetail.post_code} onChange={handleInputChange} className="form-control" placeholder="Your Post Code" />
                                    </div>
                                    <div className="col-sm-4 form-group mb-3">
                                        <label for="">Email</label>
                                        <input type="text" name="email" value={BookingDetail.email} onChange={handleInputChange} className="form-control" placeholder="Your Email Address" />
                                    </div>
                                    <div className="col-sm-4 form-group mb-3">
                                        <label for="">Request/Occasion</label>
                                        <input type="text" name="note" value={BookingDetail.note} onChange={handleInputChange} className="form-control" placeholder="Your Note" />
                                    </div>
                                    <div className="col-sm-12 text-center mt-4">
                                        <button disabled={ButtonDisabled} type="submit" className='btn btn-primary c-btn-primary pt-1 pb-1 pl-3 pr-3'>{ButtonDisabled && <i className="fa fa-spinner me-2 fa-spin"></i>} Submit</button>
                                        <Link to="/table-booking" className='btn btn-danger c-btn-danger ml-3 pt-1 pb-1 pl-3 pr-3'>Cancel</Link>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            <StripeCheckout2
                className="btn btn-success StripeCheckout2 none"
                stripeKey={process.env.REACT_APP_STRIPE_KEY}
                token={handleToken}
                email={BookingDetail.email}
                currency={process.env.REACT_APP_CURRENCY}
                amount={0}
                name={'We dont charge your card'}
                panelLabel="Make Reservation"
                billingAddress={false}
                alipay={false}
            />
        </>
    );
}
export default withRouter(ChangePassword)
