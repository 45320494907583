import React, { useState, useEffect } from "react";
import { useHistory, withRouter, Link } from "react-router-dom"
import { post } from '../../helpers/api_helper';
import { POST_GET_ALL_ORDER_LIST, POST_GET_DETAIL, POST_UPDATE_ORDER_STATUS } from '../../helpers/url_helper';
import { Helmet } from "react-helmet";
import { getToken, IsModuleAccess } from '../../helpers/Helper';
import { toast } from 'react-toastify';
import ReactDOM from 'react-dom';

import $ from 'jquery';
import "datatables.net-dt/js/dataTables.dataTables";


const TagsList = props => {

    let history = useHistory();
    if (!IsModuleAccess('order')) {
        history.push("/");
    }
    const [orderStaus, SetOrderStaus] = useState('');
    const [orderStausData, SetOrderStausData] = useState({ order_id: '', order_status: '', payment_status: '', 'type': '' });
    const [orderDetail, SetorderDetail] = useState({});

    useEffect(() => {
        get_list();
    }, [])

    // get list
    const get_list = () => {
        $(document).ready(function () {
            $('#dataTableExample').DataTable({
                destroy: true,
                "bProcessing": true,
                "bServerSide": true,
                'searching': true,
                'stateSave': true,
                "scrollX": true,
                "sServerMethod": "POST",
                "sAjaxSource": POST_GET_ALL_ORDER_LIST,
                "order": [[0, 'desc']],
                columnDefs: [{ orderable: false, targets: [8,9,10] }, { "targets": 0, "visible": false }],
                "fnServerData": function (sSource, aoData, fnCallback) {
                    $.ajax({
                        "dataType": 'json',
                        "type": "POST",
                        "url": sSource,
                        "data": aoData,
                        "success": fnCallback,
                        headers: { 'Authorization': getToken() },
                    });
                },
                "columns": [
                    { "data": "order_id" },
                    { "data": "order_number" },
                    { "data": "order_date" },
                    { "data": "pickp_date" },
                    { "data": "name" },
                    { "data": "email" },
                    { "data": "total_qty" },
                    { "data": "grand_total" },
                    {
                        "data": null, 'createdCell': (td, data) => {
                            ReactDOM.render(<>
                                <div className="pointer" onClick={() => { SetOrderStaus(data.order_status); SetOrderStausData({ order_id: data.order_id, order_status: data.order_status, payment_status: '', 'type': 'order' ,order_type : data.order_type }); }} data-toggle="modal" data-target="#UpdateStatusModal">
                                    {data.order_status === 'Pending' && <button type="button" className="border-0 pointer badge badge-primary pt-1 pb-1"  >Pending</button>}
                                    {data.order_status === 'Confirmed' && <button type="button" className="border-0 pointer badge badge-info pt-1 pb-1" >Confirmed</button>}
                                    {data.order_status === 'Ready For Pickup' && <button type="button" className="border-0 pointer badge badge-warning pt-1 pb-1" >Ready For Pickup</button>}
                                    {data.order_status === 'Complete' && <button type="button" className="border-0 pointer badge badge-success pt-1 pb-1" >Complete</button>}
                                    {data.order_status === 'Cancelled' && <button type="button" className="border-0 pointer badge badge-danger pt-1 pb-1" >Cancelled</button>}
                                </div>
                            </>, td)
                        }
                    },
                    {
                        "data": null, 'createdCell': (td, data) => {
                            ReactDOM.render(<>
                                <div className="pointer" onClick={() => { SetOrderStaus(data.order_status); SetOrderStausData({ order_id: data.order_id, order_status: '', payment_status: data.payment_status, 'type': 'payment',order_type : data.order_type }); }} data-toggle="modal" data-target="#UpdateStatusModal">
                                    {data.payment_status === 'Pending' && <button type="button" className="border-0 pointer badge badge-primary pt-1 pb-1"  >Pending</button>}
                                    {data.payment_status === 'Complete' && <button type="button" className="border-0 pointer badge badge-success pt-1 pb-1" >Complete</button>}
                                    {data.payment_status === 'Failed' && <button type="button" className="border-0 pointer badge badge-danger pt-1 pb-1" >Failed</button>}
                                    {data.payment_status === 'Cancelled' && <button type="button" className="border-0 pointer badge badge-danger pt-1 pb-1" >Cancelled</button>}
                                </div>
                            </>, td)
                        }
                    },
                    {
                        "data": null, 'createdCell': (td, data) => {
                            ReactDOM.render(<>
                                <button class="btn btn-sm btn-primary pt-0 pb-0 pl-1 pr-1  mr-2" onClick={get_order_detail.bind(this, data.order_id)} data-toggle="modal" data-target="#OrderDetaileModel" ><i class="fa fa-eye" style={{ 'fontSize': '12px' }} aria-hidden="true"></i></button>
                            </>, td)
                        }
                    },
                ]
            });
        });
    }

    // get_order_detail
    const get_order_detail = async (id) => {
        SetorderDetail({});
        var res_data = await post(POST_GET_DETAIL, { order_id: id });
        if (res_data.status) {
            SetorderDetail(res_data.detail.detail);
        } else {
            toast.error(res_data.message);
        }
    }

    // input text change handler
    const handleInputChange = (event) => {
        event.persist();
        SetOrderStausData(inputs => ({ ...orderStausData, [event.target.name]: event.target.value }));
    }

    //UpdateStatus
    const UpdateStatus = async () => {
        var res_data = await post(POST_UPDATE_ORDER_STATUS, orderStausData);
        if (res_data.status) {
            toast(res_data.message);
            get_list();
        } else {
            toast.error(res_data.message);
        }
    }

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>ONDA | Order List</title>
            </Helmet>

            <div className='row mt-3'>
                <div className='col-sm-12'>
                    <div className='card'>
                        <div className="card-header">
                            <div className="row">
                                <div className="col-sm-6">
                                    <h6 className='mt-2 mb-0'>Order List</h6>
                                </div>
                                <div className="col-sm-6 text-right">
                                    {/* <Link to="vouchers/add" className="btn btn-sm btn-primary pt-1 pb-1 pl-3 pr-3 c-btn-primary text-white">+ Add</Link> */}
                                </div>
                            </div>
                        </div>
                        <div className='card-body'>
                            <div className="mt-2 mb-5">
                                <table id="dataTableExample" className="table table-striped  table-align-center">
                                    <thead>
                                        <tr>
                                            <td>Id</td>
                                            <td>Order No</td>
                                            <td>Order Date</td>
                                            <td>Pickp Date</td>
                                            <td>User Name</td>
                                            <td>Email</td>
                                            <td>Total Qty</td>
                                            <td>Total Amount</td>
                                            <td>Order Status</td>
                                            <td>Payment Status</td>
                                            <td>Action</td>
                                        </tr>
                                    </thead>
                                    <tbody>

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="OrderDetaileModel" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Order Detailes</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="row">
                                    <div className="col-sm-6">
                                        <p className="mb-1"><b>Name :</b> {orderDetail.name}</p>
                                        <p className="mb-1"><b>Email :</b> {orderDetail.email}</p>
                                        <p className="mb-1"><b>Phone Number :</b> {orderDetail.phone_number}</p>
                                        <p className="mb-1"><b>Order Status : </b>
                                            {orderDetail.order_status === 'Pending' && <span className="text-primary">{orderDetail.order_status}</span>}
                                            {orderDetail.order_status === 'Confirmed' && <span className="text-info">{orderDetail.order_status}</span>}
                                            {orderDetail.order_status === 'Ready For Pickup' && <span className="text-warning">{orderDetail.order_status}</span>}
                                            {orderDetail.order_status === 'Complete' && <span className="text-success">{orderDetail.order_status}</span>}
                                            {orderDetail.order_status === 'Cancelled' && <span className="text-danger">{orderDetail.order_status}</span>}
                                        </p>
                                    </div>
                                    <div className="col-sm-6 text-right">
                                        <p className="mb-1"><b>Order No :</b> {orderDetail.order_number}</p>
                                        <p className="mb-1"><b>Order Date :</b> {orderDetail.order_date}</p>
                                        <p className="mb-1"><b>Pickp Date :</b> {orderDetail.pickp_date}</p>
                                    </div>
                                </div>
                                <table className="table table-bordered mt-4">
                                    <thead>
                                        <tr>
                                            <th className="pt-2 pb-2" width="50">Sr.</th>
                                            <th className="pt-2 pb-2">Name</th>
                                            <th className="pt-2 pb-2" width="120">Qty</th>
                                            <th className="pt-2 pb-2" width="150">Price</th>
                                            <th className="pt-2 pb-2" width="100">Total</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {orderDetail.order_detail && Object.entries(orderDetail.order_detail).map(([key, value]) => {
                                            return (<tr key={key}>
                                                <td className="pt-2 pb-2">{++key}</td>
                                                <td className="pt-2 pb-2">{value.product_name} {value.title}</td>
                                                <td className="pt-2 pb-2">{value.qty} ({value.qty_type})</td>
                                                <td className="pt-2 pb-2">${value.price}</td>
                                                <td className="pt-2 pb-2">${value.total_amount}</td>
                                            </tr>)
                                        })}
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <td className="pt-2 pb-2 text-center" colSpan={2}><b>Total</b></td>
                                            <td className="pt-2 pb-2"><b>{orderDetail.total_qty}</b></td>
                                            <td className="pt-2 pb-2"></td>
                                            <td className="pt-2 pb-2"><b>${orderDetail.sub_total}</b></td>
                                        </tr>
                                        {/* <tr>
                                            <td className="pt-2 pb-2" rowSpan={4} colSpan={3}>
                                                <b>Note.</b>
                                                <p>{orderDetail.order_note}</p>
                                            </td>
                                            <td className="pt-2 pb-2">GST ({orderDetail.tax_pre})%</td>
                                            <td className="pt-2 pb-2"><b>${orderDetail.tax_amount}</b></td>
                                        </tr> */}
                                        <tr>
                                            <td className="pt-2 pb-2" rowSpan={4} colSpan={3}>
                                                <b>Note.</b>
                                                <p>{orderDetail.order_note}</p>
                                            </td>
                                            <td className="pt-2 pb-2">Tip</td>
                                            <td className="pt-2 pb-2"><b>${orderDetail.tip}</b></td>
                                        </tr>
                                        <tr>
                                            <td className="pt-2 pb-2">Grand Total</td>
                                            <td className="pt-2 pb-2"><b>${orderDetail.grand_total}</b></td>
                                        </tr>
                                        <tr>
                                            <td className="pt-2 pb-2">Promo code {orderDetail.promo_code}</td>
                                            <td className="pt-2 pb-2"><b>- ${orderDetail.discount}</b></td>
                                        </tr>
                                        <tr>
                                            <td className="pt-2 pb-2">Payable Amount</td>
                                            <td className="pt-2 pb-2"><b>${orderDetail.payable_amount}</b></td>
                                        </tr>
                                    </tfoot>
                                </table>
                                <div className="row">
                                    <div className="col-sm-12">
                                        <h6 className="mt-3">Payment Info</h6>
                                    </div>
                                    <div className="col-sm-6">
                                        <p><b>Payment Transaction Id : </b>{orderDetail.payment_transaction_id}</p>
                                    </div>
                                    <div className="col-sm-6 text-right">
                                        <p className="mb-1"><b>Payment Status : </b>
                                            {orderDetail.payment_status === 'Pending' && <span className="text-primary">{orderDetail.payment_status}</span>}
                                            {orderDetail.payment_status === 'Complete' && <span className="text-success">{orderDetail.payment_status}</span>}
                                            {orderDetail.payment_status === 'Failed' && <span className="text-danger">{orderDetail.payment_status}</span>}
                                            {orderDetail.payment_status === 'Cancelled' && <span className="text-danger">{orderDetail.payment_status}</span>}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="modal-footer">
                                <button type="button" className="btn btn-sm btn-dnager c-btn-danger text-white" data-dismiss="modal">No</button>
                                <button onClick={remove_row.bind(this)} data-dismiss="modal" className="btn btn-sm btn-primary c-btn-primary">Yes</button>
                            </div> */}
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="UpdateStatusModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Update Order Status</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="row">
                                    {orderStausData.type == "order" && <div className="col-sm-12">
                                        <div className="form-group mb-3">
                                            <label className="form-label text-capitalize">Order Status</label>
                                            <select name="order_status" className="form-control" value={orderStausData.order_status} onChange={handleInputChange}>
                                                {orderStaus === 'Pending' && <option value="Pending">Pending</option>}
                                                {(orderStausData.order_type == 'Order' && (orderStaus === 'Pending' || orderStaus === 'Confirmed')) && <option value="Confirmed">Confirmed</option>}
                                                {(orderStausData.order_type == 'Order' && (orderStaus === 'Confirmed' || orderStaus === 'Ready For Pickup')) && <option value="Ready For Pickup">Ready For Pickup</option>}
                                                {(orderStaus === 'Ready For Pickup' || orderStaus === 'Complete' || orderStausData.order_type == 'Voucher') && <option value="Complete">Complete</option>}
                                                {(orderStaus === 'Pending' || orderStaus === 'Cancelled'  ) && <option value="Cancelled">Cancelled</option>}
                                            </select>
                                        </div>
                                    </div>}
                                    {orderStausData.type != "order" &&<div className="col-sm-12">
                                        <div className="form-group mb-3">
                                            <label className="form-label text-capitalize">Payment Status</label>
                                            <select name="payment_status" className="form-control" value={orderStausData.payment_status} onChange={handleInputChange}>
                                                <option value="Pending">Pending</option>
                                                <option value="Complete">Complete</option>
                                                <option value="Failed">Failed</option>
                                                <option value="Cancelled">Cancelled</option>
                                            </select>
                                        </div>
                                    </div>}
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-sm btn-dnager c-btn-danger text-white" data-dismiss="modal">Cancel</button>
                                <button onClick={UpdateStatus.bind(this)} data-dismiss="modal" className="btn btn-sm btn-primary c-btn-primary">Update</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default withRouter(TagsList)
