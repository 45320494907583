
const getToken = () => {
    return sessionStorage.getItem('token') || null;
}

const getUserDetail = () => {
    return JSON.parse(sessionStorage.getItem('user_detail'));
}

const getUserId = () => {
    return (sessionStorage.getItem('user_detail')) ? JSON.parse(sessionStorage.getItem('user_detail'))['user_id'] : 0;
}

const getUserType = () => {
    if (sessionStorage.getItem('user_detail')) {
        return JSON.parse(sessionStorage.getItem('user_detail'))['u_type'];
    } else {
        return 0;
    }
}

const removeUserSession = () => {
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('user_detail');
    localStorage.clear();
}

const setUserSession = (token, user) => {
    sessionStorage.setItem('token', token);
    sessionStorage.setItem('user_detail', JSON.stringify(user));
}

const is_login = () => {
    if (sessionStorage.getItem('token')) {
        return true;
    } else {
        return false;
    }
}

const amountFormat = (amount) => {
    return parseFloat(amount).toFixed(2);
}

const IsModuleAccess = (modem_name) => {
    var mode = JSON.parse(JSON.parse(sessionStorage.getItem('user_detail'))['role_access']);
    if (mode[modem_name] && mode[modem_name].module_access === 1) {
        return true;
    } else {
        return false;
    }
}
const img_view = (img, width = '', height = '', auto = true) => {
    return process.env.REACT_APP_IMG_URL + 'uploads/' + img
    if (auto) {
        return process.env.REACT_APP_IMG_URL + 'img?img=' + img + '&w=' + width + '&h=' + height
    } else {
        return process.env.REACT_APP_IMG_URL + 'uploads/' + img
    }
}
const Date_format = (date) => {
    var date = new Date(date),
        mnth = ("0" + (date.getMonth() + 1)).slice(-2),
        day = ("0" + date.getDate()).slice(-2);
    return  [date.getFullYear(), mnth, day].join("-");
}
export { Date_format,img_view, amountFormat, getUserType, getToken, removeUserSession, setUserSession, is_login, getUserDetail, getUserId, IsModuleAccess };