import React, { useState, useEffect, useRef } from 'react';
import { withRouter } from "react-router-dom";
import { post, get } from '../../helpers/api_helper';
import { img_view, IsModuleAccess } from '../../helpers/Helper';
import Loader from '../../component/Loader';
import { GET_TIME_SLOT, POST_ADD_EDIT_BUSINESS_HOURS, POST_BUSINESS_HOURS_DETAIL } from '../../helpers/url_helper';
import { toast } from 'react-toastify';
import SimpleReactValidator from 'simple-react-validator';
import { Helmet } from "react-helmet";
import { Link, useHistory } from "react-router-dom";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const ChangePassword = props => {
    let history = useHistory();
    if (!IsModuleAccess('category')) {
        history.push("/");
    }

    const [isLoader, setLoader] = useState(0);
    const [ButtonDisabled, SetButtonDisabled] = useState(false);
    const [timeSlotList, setTimeSlotList] = useState([]);
    const [form_inputs, setInputs] = useState({});
    const [title, setTitle] = useState('Add Business Hours');
    
    const simpleValidator = useRef(new SimpleReactValidator());
    const [, forceUpdate] = useState();
    
    const [startDate, setStartDate] = useState(new Date());

    useEffect(() => {
        async function get_detaile() {
            await get_form_option();
            if (props.match.params.id) {
                setTitle('Edit Business Hours');
                var res_data = await post(POST_BUSINESS_HOURS_DETAIL, { 'id': props.match.params.id }, {});
                if (res_data.status) {
                    setInputs({ 
                        'business_hours_id' : res_data.data.detail.business_hours_id,
                        'type' : res_data.data.detail.type,
                        'day_name' : res_data.data.detail.day_name,
                        'start_date' : new Date(res_data.data.detail.start_date),
                        'end_date' : new Date(res_data.data.detail.end_date),
                        'open_time' : res_data.data.detail.open_time,
                        'close_time' : res_data.data.detail.close_time,
                        'last_seating' : res_data.data.detail.last_seating
                     });
                } else {
                    toast.error(res_data.message);
                    history.push("/");
                }
            }
            setLoader(1);
        }
        get_detaile();
    }, [props.match.params.id])

    const get_form_option = async () => {
        var res_data = await get(GET_TIME_SLOT);
        if (res_data.status) {
            setTimeSlotList(res_data.data.time_slot_list)
        } else {
            toast.error(res_data.message);
            history.push("/");
        }
    }

    // form submit event
    const handleSubmit = async (event) => {
        event.preventDefault();
        const formValid = simpleValidator.current.allValid()
        if (!formValid) {
            simpleValidator.current.showMessages(true);
            forceUpdate(1);
        } else {
            console.log(form_inputs);
            SetButtonDisabled(true);
            var res_data = await post(POST_ADD_EDIT_BUSINESS_HOURS, form_inputs, {});
            if (res_data.status) {
                toast(res_data.message);
                history.push("/setting/business-hours");
            } else {
                toast.error(res_data.message);
            }
            SetButtonDisabled(false);
        }
    }

    // input text change handler
    const handleInputChange = (event) => {
        event.persist();
        setInputs(inputs => ({ ...form_inputs, [event.target.name]: event.target.value }));
    }


    if (!isLoader) {
        return (<Loader></Loader>);
    }

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>ONDA | {title}</title>
            </Helmet>

            <div className='row mt-3'>
                <div className='col-sm-6'>
                    <div className='card'>
                        <div className="card-header">
                            <h6 className='m-0'>{title}</h6>
                        </div>
                        <div className='card-body'>
                            <form className="av-invalid" onSubmit={handleSubmit}>
                                <div className='row'>
                                    <div className='col-sm-12'>
                                        <div className="form-group mb-3">
                                            <label className="form-label text-capitalize">Select Allocation </label>
                                            <select name="type" value={form_inputs.type} onChange={handleInputChange} className="form-control" >
                                                <option value="">Select Allocation</option>
                                                <option value="Week">Week</option>
                                                <option value="Date">Date</option>
                                                <option value="Online Order Stop">Online Order Stop</option>
                                                <option value="Table Book Stop">Table Book Stop</option>
                                                <option value="Bottomless Brunch">Bottomless Brunch</option>
                                                <option value="Bottomless Brunch Stop">Bottomless Brunch Stop</option>
                                            </select>
                                            {simpleValidator.current.message('type', form_inputs.type, 'required')}
                                        </div>
                                        {(form_inputs.type === 'Week' || form_inputs.type === 'Bottomless Brunch' ) && <div className="form-group mb-3">
                                            <label className="form-label text-capitalize">Select Day </label>
                                            <select name="day_name" value={form_inputs.day_name} onChange={handleInputChange} className="form-control" >
                                                <option value="">Select Day</option>
                                                <option value="Monday">Monday</option>
                                                <option value="Tuesday">Tuesday</option>
                                                <option value="Wednesday">Wednesday</option>
                                                <option value="Thursday">Thursday</option>
                                                <option value="Friday">Friday</option>
                                                <option value="Saturday">Saturday</option>
                                                <option value="Sunday">Sunday</option>
                                            </select>
                                        </div>}
                                        {(form_inputs.type !== 'Week' && form_inputs.type !== 'Bottomless Brunch' ) && <div className="form-group mb-3">
                                            <label className="form-label text-capitalize">Start Date</label>
                                            <DatePicker selected={form_inputs.start_date}  dateFormat='dd-MM-yyyy' className="form-control" onChange={(date) =>{setInputs(inputs => ({ ...form_inputs, ['start_date']: date }));} } />
                                        </div>}
                                        <div className="form-group mb-3">
                                            <label className="form-label text-capitalize">Select Start Time</label>
                                            <select name="open_time" value={form_inputs.open_time} onChange={handleInputChange} className="form-control" >
                                                <option value="">Select Time</option>
                                                {timeSlotList && Object.entries(timeSlotList).map(([key, value]) => (
                                                    <option key={key} value={value.label}>{value.label}</option>
                                                ))}
                                            </select>
                                            {simpleValidator.current.message('open time', form_inputs.open_time, 'required')}
                                        </div>
                                        {(form_inputs.type !== 'Week' && form_inputs.type !== 'Bottomless Brunch' ) && <div className="form-group mb-3">
                                            <label className="form-label text-capitalize">End Date</label>
                                            <DatePicker selected={form_inputs.end_date} dateFormat='dd-MM-yyyy' className="form-control" onChange={(date) => {setInputs(inputs => ({ ...form_inputs, ['end_date']: date }));}} />
                                        </div>}
                                        <div className="form-group mb-3">
                                            <label className="form-label text-capitalize">Select End Time</label>
                                            <select name="close_time" value={form_inputs.close_time} onChange={handleInputChange} className="form-control" >
                                                <option value="">Select Time</option>
                                                {timeSlotList && Object.entries(timeSlotList).map(([key, value]) => (
                                                    <option key={key} value={value.label}>{value.label}</option>
                                                ))}
                                            </select>
                                                {simpleValidator.current.message('end time', form_inputs.close_time, 'required')}
                                        </div>
                                        <div className="form-group mb-3">
                                            <label className="form-label text-capitalize">Last Seating</label>
                                            <select name="last_seating" value={form_inputs.last_seating} onChange={handleInputChange} className="form-control" >
                                                <option value="">Select Time</option>
                                                {timeSlotList && Object.entries(timeSlotList).map(([key, value]) => (
                                                    <option key={key} value={value.label}>{value.label}</option>
                                                ))}
                                            </select>
                                            {simpleValidator.current.message('last seating', form_inputs.last_seating, 'required')}
                                        </div>
                                    </div>
                                </div>
                                <div className="text-center mt-4">
                                    <button disabled={ButtonDisabled} type="submit" className='btn btn-primary c-btn-primary pt-1 pb-1 pl-3 pr-3'>{ButtonDisabled && <i className="fa fa-spinner me-2 fa-spin"></i>} Submit</button>
                                    <Link to="/setting/business-hours" className='btn btn-danger c-btn-danger ml-3 pt-1 pb-1 pl-3 pr-3'>Cancel</Link>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default withRouter(ChangePassword)
