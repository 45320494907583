import React, { Component } from 'react';
import {Helmet} from "react-helmet";

class Dashboard extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>ONDA | Dashboard</title>
                </Helmet>
                <div className='row'>
                    <div className='col-sm-12'>
                        <h5>Welcome To ONDA</h5>
                    </div>
                </div>
                <div className='row mt-15' style={{'display':'none'}}>
                    <div className='col-sm-12 col-md-6 col-lg-2 mb-3'>
                        <div className='card'>
                            <div class="card-body text-center">
                                <h6>Total Distributor</h6>
                                <h6>10</h6>
                            </div>
                        </div>
                    </div>
                    <div className='col-sm-12 col-md-6 col-lg-2 mb-3'>
                        <div className='card'>
                            <div class="card-body text-center">
                                <h6>Total Retailer</h6>
                                <h6>10</h6>
                            </div>
                        </div>
                    </div>
                    <div className='col-sm-12 col-md-6 col-lg-2 mb-3'>
                        <div className='card'>
                            <div class="card-body text-center">
                                <h6>Total Retailer</h6>
                                <h6>10</h6>
                            </div>
                        </div>
                    </div>
                    <div className='col-sm-12 col-md-6 col-lg-2 mb-3'>
                        <div className='card'>
                            <div class="card-body text-center">
                                <h6>Total Retailer</h6>
                                <h6>10</h6>
                            </div>
                        </div>
                    </div>
                    <div className='col-sm-12 col-md-6 col-lg-2 mb-3'>
                        <div className='card'>
                            <div class="card-body text-center">
                                <h6>Total Retailer</h6>
                                <h6>10</h6>
                            </div>
                        </div>
                    </div>
                    <div className='col-sm-12 col-md-6 col-lg-2 mb-3'>
                        <div className='card'>
                            <div class="card-body text-center">
                                <h6>Total Retailer</h6>
                                <h6>10</h6>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default Dashboard;
