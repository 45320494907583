import React, { useState, useEffect } from 'react';
import { withRouter } from "react-router-dom";
import { post, get, del } from '../../helpers/api_helper';
import Loader from '../../component/Loader';
import { GET_BUSINESS_HOURS, POST_UPDATE_BUSINESS_HOURS_STATUS, DELETE_BUSINESS_HOURS } from '../../helpers/url_helper';
import { toast } from 'react-toastify';
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

const ChangePassword = props => {

    const [isLoader, setLoader] = useState(0);
    const [DayList, setDayList] = useState();
    const [SpecialHours, setSpecialHours] = useState([]);
    const [id, SetId] = useState(0);

    useEffect(() => {
        async function get_detaile() {
            await get_business_hours();
            setLoader(1);
        }
        get_detaile();
    }, [])

    const get_business_hours = async () => {
        var res_data = await get(GET_BUSINESS_HOURS);
        if (res_data.status) {
            await setDayList(res_data.data.day_list);
            await setSpecialHours(res_data.data.special_hours);
        } else {
            toast.error(res_data.message);
        }
    }

    //UpdateStatus
    const UpdateStatus = async (id, status) => {
        var res_data = await post(POST_UPDATE_BUSINESS_HOURS_STATUS, { id: id, status: status });
        if (res_data.status) {
            toast(res_data.message);
            get_business_hours();
        } else {
            toast.error(res_data.message);
        }
    }

    // remove user role
    const remove_row = async () => {
        var res_data = await del(DELETE_BUSINESS_HOURS + "?id=" + id);
        if (res_data.status) {
            toast(res_data.message);
            get_business_hours();
        } else {
            toast.error(res_data.message);
        }
    }


    if (!isLoader) {
        return (<Loader></Loader>);
    }

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>ONDA | Business Hours Setting</title>
            </Helmet>

            <div className='row mt-3'>
                <div className='col-sm-12'>
                    <div className='card'>
                        <div class="card-header">
                            <div className='row'>
                                <div className='col-6'>
                                    <h6 className='mt-2'>Business Hours Setting</h6>
                                </div>
                                <div className='col-6 text-right'>
                                    <Link to='/setting/business-hours/add' className="btn btn-sm btn-primary pt-1 pb-1 pl-3 pr-3 c-btn-primary text-white">+ Add</Link>
                                </div>
                            </div>
                        </div>
                        <div className='card-body'>
                            {DayList && Object.entries(DayList).map(([key, day]) => {
                                return (<><div className='row' key={key}>
                                    <div className='col-sm-12'>
                                        <h6>{day.name}</h6>
                                        <table className='table table-small'>
                                            <thead>
                                                <tr className='table-secondary'>
                                                    <td className="pt-2 pb-2" width="200">Type</td>
                                                    <td className="pt-2 pb-2">Open Time</td>
                                                    <td className="pt-2 pb-2">Close Time</td>
                                                    <td className="pt-2 pb-2">Last Seating</td>
                                                    <td className="pt-2 pb-2">Status</td>
                                                    <td className="pt-2 pb-2">Action</td>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {Object.entries(day.list).map(([key, time]) => {
                                                    return (<tr key={key}>
                                                        <td className="pt-2 pb-2">{time.type}</td>
                                                        <td className="pt-2 pb-2">{time.open_time}</td>
                                                        <td className="pt-2 pb-2">{time.close_time}</td>
                                                        <td className="pt-2 pb-2">{time.close_time}</td>
                                                        <td className="pt-2 pb-2">
                                                            {time.status === 1 && <button onClick={UpdateStatus.bind(this, time.business_hours_id, time.status)} class="border-0 badge badge-success pt-1 pb-1"  >Active</button>}
                                                            {time.status === 0 && <button onClick={UpdateStatus.bind(this, time.business_hours_id, time.status)} class="border-0 badge badge-danger pt-1 pb-1" >Deactive</button>}
                                                        </td>
                                                        <td className="pt-2 pb-2">
                                                            <Link class="btn btn-sm btn-primary pt-0 pb-0 pl-1 pr-1  mr-2" to={`/setting/business-hours/edit/${time.business_hours_id}`} ><i class="fa fa-pencil" style={{ 'fontSize': '12px' }} aria-hidden="true"></i></Link>
                                                            <button class="btn btn-sm btn-danger pt-0 pb-0 pl-1 pr-1  mr-2" onClick={() => { SetId(time.business_hours_id); }} data-toggle="modal" data-target="#deleteModal" ><i class="fa fa-trash-o" style={{ 'fontSize': '12px' }} aria-hidden="true"></i></button>
                                                        </td>
                                                    </tr>)
                                                })}
                                                {day.list.length <= 0 && <tr>
                                                    <td className='text-center pt-1 pb-1' colSpan={5}>No any time</td>
                                                </tr>}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                </>)
                            })}
                            <hr />
                            <div className='row'>
                                <div className='col-sm-12 mb-3'>
                                    <h5>Special Business Hours</h5>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-sm-12 mb-3'>
                                    <table className='table table-small'>
                                        <thead>
                                            <tr className='table-secondary'>
                                                <td className="pt-1 pb-1">Type</td>
                                                <td className="pt-1 pb-1">Start Date</td>
                                                <td className="pt-1 pb-1">End Time</td>
                                                <td className="pt-1 pb-1">Last Seating</td>
                                                <td className="pt-1 pb-1">Status</td>
                                                <td className="pt-1 pb-1">Action</td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {Object.entries(SpecialHours).map(([key, time]) => {
                                                return (<tr key={key}>
                                                    <td className="pt-1 pb-1">{time.type}</td>
                                                    <td className="pt-1 pb-1">{time.start_date} {time.open_time}</td>
                                                    <td className="pt-1 pb-1">{time.end_date} {time.close_time}</td>
                                                    <td className="pt-1 pb-1">{time.last_seating}</td>
                                                    <td className="pt-1 pb-1">
                                                        {time.status === 1 && <button onClick={UpdateStatus.bind(this, time.business_hours_id, time.status)} class="border-0 badge badge-success pt-1 pb-1"  >Active</button>}
                                                        {time.status === 0 && <button onClick={UpdateStatus.bind(this, time.business_hours_id, time.status)} class="border-0 badge badge-danger pt-1 pb-1" >Deactive</button>}
                                                    </td>
                                                    <td className="pt-1 pb-1">
                                                        <Link class="btn btn-sm btn-primary pt-0 pb-0 pl-1 pr-1  mr-2" to={`/setting/business-hours/edit/${time.business_hours_id}`} ><i class="fa fa-pencil" style={{ 'fontSize': '12px' }} aria-hidden="true"></i></Link>
                                                        <button class="btn btn-sm btn-danger pt-0 pb-0 pl-1 pr-1  mr-2" onClick={() => { SetId(time.business_hours_id); }} data-toggle="modal" data-target="#deleteModal" ><i class="fa fa-trash-o" style={{ 'fontSize': '12px' }} aria-hidden="true"></i></button>
                                                    </td>
                                                </tr>)
                                            })}
                                            {SpecialHours.length <= 0 && <tr>
                                                <td className='text-center pt-1 pb-1' colSpan={6}>No any time</td>
                                            </tr>}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="deleteModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Confirm Delete</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <p>Are you sure you want to delete this record?</p>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-sm btn-dnager c-btn-danger text-white" data-dismiss="modal">No</button>
                            <button onClick={remove_row.bind(this)} data-dismiss="modal" className="btn btn-sm btn-primary c-btn-primary">Yes</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default withRouter(ChangePassword)
