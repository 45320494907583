import React, { useState, useEffect, useRef } from 'react';
import { withRouter } from "react-router-dom";
import { post, get } from '../../helpers/api_helper';
import { img_view, IsModuleAccess } from '../../helpers/Helper';
import Loader from '../../component/Loader';
import { POST_ADD_EDIT_PRODUCT, POST_GET_PRODUCT_DETAIL, POST_GET_CATEGORY } from '../../helpers/url_helper';
import { toast } from 'react-toastify';
import SimpleReactValidator from 'simple-react-validator';
import { Helmet } from "react-helmet";
import { Link, useHistory } from "react-router-dom";

const ChangePassword = props => {
    let history = useHistory();
    if (!IsModuleAccess('product')) {
        history.push("/");
    }

    const [isLoader, setLoader] = useState(0);
    const simpleValidator = useRef(new SimpleReactValidator());
    const [ButtonDisabled, SetButtonDisabled] = useState(false);
    const [categoriesList, setCategoriesList] = useState([]);
    const [subcategoriesList, setSubCategoriesList] = useState([]);
    const [form_inputs, setInputs] = useState({ 'product_id': 0, 'product_name': '', 'image': '', 'product_description': '', 'category_id': '', 'sub_category_id': '', 'qty_type': '', 'price': '', 'glass_price': '' });
    const [title, setTitle] = useState('Add Product');
    const [, forceUpdate] = useState();

    useEffect(() => {
        async function get_detaile() {
            await get_category('main', 0);
            if (props.match.params.id) {
                setTitle('Edit Product');
                var res_data = await post(POST_GET_PRODUCT_DETAIL, { 'product_id': props.match.params.id }, {});
                if (res_data.status) {
                    setInputs(res_data.detail);
                    await get_category('sub', res_data.detail.category_id);
                } else {
                    toast.error(res_data.message);
                    history.push("/");
                }
            }
            setLoader(1);
        }
        get_detaile();
    }, [props.match.params.id])

    const get_category = async (type, category_id = 0) => {
        var res_data = await post(POST_GET_CATEGORY, { parent_category_id: category_id, type: type });
        if (res_data.status) {
            if (type == 'main') {
                setCategoriesList(res_data.data.categories_list)
            } else {
                setSubCategoriesList(res_data.data.categories_list)
            }
        } else {
            toast.error(res_data.message);
            history.push("/");
        }
    }

    // form submit event
    const handleSubmit = async (event) => {
        event.preventDefault();
        const formValid = simpleValidator.current.allValid()
        if (!formValid) {
            simpleValidator.current.showMessages(true);
            forceUpdate(1);
        } else {
            SetButtonDisabled(true);
            var res_data = await post(POST_ADD_EDIT_PRODUCT, form_inputs, {});
            if (res_data.status) {
                toast(res_data.message);
                history.push("/products");
            } else {
                toast.error(res_data.message);
            }
            SetButtonDisabled(false);
        }
    }

    // input text change handler
    const handleInputChange = (event) => {
        event.persist();
        if (event.target.name === 'image') {
            var file_extension = event.target.files[0].name.split('.').pop();
            var file_size = event.target.files[0].size;
            var file_size = (file_size / 1024 / 1024).toFixed(1);

            var extension = ['png','jpg', 'jpeg'];
            if (!extension.includes(file_extension.toLowerCase())) {
                toast.error('Alludu only file png, jpg and jpeg.');
                event.target.value = null;
                return false;
            }

            if (file_size > 1) {
                toast.error('Max File size Allowed 1Mb.');
                event.target.value = null;
                return false;
            }

            var file = event.target.files[0];
            var reader = new FileReader();
            reader.onload = function () {
                setInputs(inputs => ({ ...form_inputs, [event.target.name]: reader.result }));
            }
            reader.readAsDataURL(file);
        } else {
            if (event.target.name == 'category_id') {
                form_inputs.sub_category_id = '';
                get_category('sub', event.target.value);
            }
            setInputs(inputs => ({ ...form_inputs, [event.target.name]: event.target.value }));
        }
    }


    if (!isLoader) {
        return (<Loader></Loader>);
    }

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>ONDA | {title}</title>
            </Helmet>

            <div className='row mt-3'>
                <div className='col-sm-12'>
                    <div className='card'>
                        <div className="card-header">
                            <h6 className='m-0'>{title}</h6>
                        </div>
                        <div className='card-body'>
                            <form className="av-invalid" onSubmit={handleSubmit}>
                                <div className='row'>
                                    <div className='col-sm-6'>
                                        <div className="form-group mb-3">
                                            <label className="form-label text-capitalize">Select Category</label>
                                            <select name="category_id" value={form_inputs.category_id} onChange={handleInputChange} className="form-control" >
                                                <option value="">Select Category</option>
                                                {categoriesList && Object.entries(categoriesList).map(([key, value]) => (
                                                    <option key={key} value={value.value}>{value.label}</option>
                                                ))}
                                            </select>
                                            {simpleValidator.current.message('category', form_inputs.category_id, 'required')}
                                        </div>
                                        <div className="form-group mb-3">
                                            <label className="form-label text-capitalize">Select Sub Category</label>
                                            <select name="sub_category_id" value={form_inputs.sub_category_id} onChange={handleInputChange} className="form-control" >
                                                <option value="">Select Sub Category</option>
                                                {subcategoriesList && Object.entries(subcategoriesList).map(([key, value]) => (
                                                    <option key={key} value={value.value}>{value.label}</option>
                                                ))}
                                            </select>
                                            {simpleValidator.current.message('sub category', form_inputs.sub_category_id, 'required')}
                                        </div>
                                        <div className="form-group mb-3">
                                            <label className="form-label text-capitalize">Name</label>
                                            <input name="product_name" value={form_inputs.product_name} onChange={handleInputChange} className="form-control" type="text" />
                                            {simpleValidator.current.message('name', form_inputs.product_name, 'required')}
                                        </div>
                                        <div className="form-group mb-3">
                                            <label className="form-label text-capitalize">Description</label>
                                            <textarea name="product_description" value={form_inputs.product_description} onChange={handleInputChange} className="form-control" />
                                            {/* {simpleValidator.current.message('description', form_inputs.product_description, 'required')} */}
                                        </div>
                                    </div>
                                    <div className='col-sm-6'>
                                        <div className='row'>
                                            <div className='col-sm-4'>
                                                <div className="form-group mb-3">
                                                    <label className="form-label text-capitalize">Select Type</label>
                                                    <select name="qty_type" value={form_inputs.qty_type} onChange={handleInputChange} className="form-control" >
                                                        <option value="">Select Type</option>
                                                        <option value="Plates">Plates</option>
                                                        <option value="Glass Bottle">Glass Bottle</option>
                                                    </select>
                                                    {simpleValidator.current.message('Qty Type', form_inputs.qty_type, 'required')}
                                                </div>
                                            </div>
                                            <div className='col-sm-4'>
                                                <div className="form-group mb-3">
                                                    {form_inputs.qty_type != 'Glass Bottle' && <label className="form-label text-capitalize">Price</label>}
                                                    {form_inputs.qty_type == 'Glass Bottle' && <label className="form-label text-capitalize">Bottle Price</label>}
                                                    <input name="price" value={form_inputs.price} onChange={handleInputChange} className="form-control" type="text" />
                                                    {simpleValidator.current.message('price', form_inputs.price, 'required')}
                                                </div>
                                            </div>
                                            <div className='col-sm-4'>
                                                {form_inputs.qty_type == 'Glass Bottle' &&
                                                    <div className="form-group mb-3">
                                                        <label className="form-label text-capitalize">Glass Price</label>
                                                        <input name="glass_price" value={form_inputs.glass_price} onChange={handleInputChange} className="form-control" type="text" />
                                                    </div>}
                                            </div>
                                        </div>
                                        <div className="form-group mb-3">
                                            <label className="form-label">Image <small className='ml-4'>Alludu file png, jpg and jpeg. Size 300px X 300px</small></label>
                                            <input name="image" onChange={handleInputChange} className="form-control" type="file" accept="image/*" />
                                        </div>
                                        {form_inputs.img_url && <div className='form-group mb-3 text-center'>
                                            <img src={img_view(form_inputs.img_url, 150,150)} width="150" />
                                        </div>}
                                    </div>
                                </div>
                                <div className="text-center mt-4">
                                    <button disabled={ButtonDisabled} type="submit" className='btn btn-primary c-btn-primary pt-1 pb-1 pl-3 pr-3'>{ButtonDisabled && <i className="fa fa-spinner me-2 fa-spin"></i>} Submit</button>
                                    <Link to="/products" className='btn btn-danger c-btn-danger ml-3 pt-1 pb-1 pl-3 pr-3'>Cancel</Link>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default withRouter(ChangePassword)
